@import '../../assets/variables';

// component

.registration {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 2em;
  text-align: center;

  h1 {
    margin: 2em 0 0;
  }

  h2 {
    margin: 1em 0 0;
  }

  p {
    margin: 2em 0;
  }

  button {
    background: $color-accent;
    border-radius: 2em;
    border: none;
    color: $color-background;
    cursor: pointer;
    font: inherit;
    max-width: 20em;
    padding: 1em;
    width: 100%;
  }

  .spinner {
    margin: 2em auto;
  }
}
